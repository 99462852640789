import React, { Component } from 'react';
import {Segment, Header, Loader} from 'semantic-ui-react';
import certificatesStore from "../stores/CertificatesStore";
import CcwDeviceResult from "./CcwDeviceResult";

export default class CcwDeviceCerts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices : null,
            loading : true
        }
    }

    componentDidMount() {
        certificatesStore.getCcwDeviceCerts().then((result) => this.setState({devices : result.deviceCerts, loading : false}))
    }

    render() {
        return (
            <Segment color='blue'>
                <Header size='large' content='CrystalControlWeb Devices' dividing textAlign='center' />


                {this.state.loading
                    ? <Loader active inline='centered' size='medium' />
                    : !this.state.devices || this.state.devices.length === 0
                        ? <Header content='No Devices Found' textAlign='center' disabled />
                        : this.state.devices.map(d => (
                            <CcwDeviceResult key={d.serialNumber} device={d} />
                        ))
                }
            </Segment>
        )
    }
}
