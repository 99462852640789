import React from 'react';
import ReactDOM from 'react-dom';
import '@ametektci/ametek.semanticuitheme/dist/semantic.min.css';
import AppWithAuth from "./AppWithAuth";
import {SetLanguage, SetEnvironment} from "@ametektci/ametek.stcappscommon"
import {ExportEnvironmentSettings} from "./utils/EnvironmentSettings";

// todo : set up language support
SetLanguage('english')
SetEnvironment(ExportEnvironmentSettings())

ReactDOM.render(<AppWithAuth />, document.getElementById('root'));
