import React, { Component } from 'react';
import {Segment, Header, Grid, Button} from 'semantic-ui-react';
import {DateUtils} from '@ametektci/ametek.stcappscommon';
import certificatesStore from "../stores/CertificatesStore";

export default class CertificateResult extends Component {
    constructor(props) {
        super(props);

        this.state = { loading : false }
    }

    loadCertificate = () => this.setState({loading : true},
        () => certificatesStore.getCertUrl(this.props.cert).then(
            (result) => {
                this.setState({loading : false});
                if (result.success)
                    window.open(result.certUri, '_blank');
            }
        ))


    render() {

        let daysSince = DateUtils.DaysUntilDate(this.props.cert.calibrationDate);
        let dateColor = daysSince < -365 ? 'red' : daysSince < -303 ? 'orange' : 'green';

        return (
            <Segment>
                <Grid>
                    <Grid.Column width={6}>
                        <Header content={this.props.cert.partNumber} subheader={'Cert '+this.props.cert.certNumber} />
                    </Grid.Column>
                    <Grid.Column width={6} textAlign='right'>
                        <Header content={DateUtils.FormatDate(this.props.cert.calibrationDate)} subheader='Calibration Date' color={dateColor}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button content='View' icon='eye' fluid color='green' onClick={this.loadCertificate} />
                    </Grid.Column>
                </Grid>
            </Segment>

        )
    }
}
