import React, { Component } from 'react';
import {Segment, Header, Input,  Divider, Button} from 'semantic-ui-react';
import certificatesStore from "../stores/CertificatesStore";
import CertificateResult from "./CertificateResult";

export default class SerialNumberSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serialNumber : '',
            results : null,
            loading : false
        }
    }

    handleChange = (e, {value}) => this.setState({serialNumber: value})

    search = () => this.setState({ loading : true}, () =>
        certificatesStore.searchCerts(this.state.serialNumber)
            .then(response => this.setState({
                results : response.certificates,
                loading : false
            }))
            .catch(err =>
                console.error('error', err)
            )
    )

    render() {
        return (
            <Segment color='blue'>
                <Header size='large' content='Search by Serial Number' dividing textAlign='center' />

                <Input size='large' placeholder='123456' fluid
                       value={this.state.serialNumber} onChange={this.handleChange}/>

                <Divider hidden />

                <Button content='Search' fluid color='blue'
                        disabled={this.state.serialNumber.length < 5}
                        onClick={this.search} loading={this.state.loading}/>

                {!this.state.results || this.state.results.length === 0 ? null :
                    <span>
                        <Divider />

                        {this.state.results.map(result => (
                            <CertificateResult key={result.certNumber} cert={result} />
                        ))}
                    </span>
                }
            </Segment>
        )
    }
}
