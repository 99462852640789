import EventEmitter from 'events';
import axios from 'axios';
import { messageQueue, authenticationStore } from '@ametektci/ametek.stcappscommon'

const RecentCertsLocalStorageKey = 'RecentCerts';

class CertificatesStore extends EventEmitter {
    constructor() {
        super();

        this.recentCerts = JSON.parse(localStorage.getItem(RecentCertsLocalStorageKey)) || [];
    }

    updateRecentCerts = (cert) => {
        this.recentCerts = this.recentCerts.filter(c => c.certNumber !== cert.certNumber);

        this.recentCerts.unshift(cert)
        if (5 < this.recentCerts.length)
            this.recentCerts = this.recentCerts.subarray(0, 5);

        localStorage.setItem(RecentCertsLocalStorageKey, JSON.stringify(this.recentCerts));
        this.emit('recentUpdated', this.recentCerts)
    }

    async searchCerts(serialNumber) {
        try {
            let response = await axios.post('/SearchCertificates', {
                JwtAccessToken : await authenticationStore.getAccessToken(),
                SerialNumber : serialNumber
            });

            return response.data;
        }
        catch (error) {
            messageQueue.sendError("Error while Searching Certificates")

            return {
                certificates : [],
                success: false,
            }
        }
    }

    async getCertUrl(cert) {
        try {
            let response = await axios.post('/LoadCertificate', {
                JwtAccessToken : await authenticationStore.getAccessToken(),
                CertNumber: cert.certNumber
            });

            this.updateRecentCerts(cert)

            return response.data;
        }
        catch (error) {
            messageQueue.sendError("Error while Loading Certificate")

            return {
                CertUri : null,
                success: false,
            }
        }
    }

    async getCcwDeviceCerts() {
        try {
            let response = await axios.post('/LoadCcwCerts', {
                JwtAccessToken : await authenticationStore.getAccessToken(),
            });

            return response.data;
        }
        catch (error) {
            messageQueue.sendError("Error while Loading CCW Certificates")

            return {
                deviceCerts : [],
                success: false,
            }
        }
    }


}

const certificatesStore = new CertificatesStore();
export default certificatesStore;