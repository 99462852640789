import React, { Component } from 'react';
import {authenticationStore} from '@ametektci/ametek.stcappscommon';
import {AmetekSignIn, AmetekSignUp, AmetekForgotPassword, AmetekConfirmSignUp, AmetekVerifyContact} from '@ametektci/ametek.authcomponents';
import App from "./App";
import { Authenticator } from "aws-amplify-react";

export default class AppWithAuth extends Component {
    render() {

        return (
            <div>
                <Authenticator hideDefault={true}
                               onStateChange={authenticationStore.handleAuthStateChange}
                               authState={'signIn'} amplifyConfig={authenticationStore.getAmplifyConfiguration()}>
                    <AmetekSignIn />
                    <AmetekSignUp />
                    <AmetekForgotPassword />
                    <AmetekConfirmSignUp />
                    <AmetekVerifyContact />
                    <App />
                </Authenticator>
            </div>
        );
    }
}