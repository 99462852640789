import React, {Component} from 'react';
import './App.css';
import AppHeader from "./global/AppHeader";
import { MessageFeed } from '@ametektci/ametek.stcappscommon';
import {Container, Grid} from 'semantic-ui-react';
import SerialNumberSearch from "./search/SerialNumberSearch";
import CcwDeviceCerts from "./ccw/CcwDeviceCerts";
import RecentCertificates from "./recent/RecentCertificates";

export default class App extends Component {
    render() {
        // if not logged in, dont show app, the sign in page will render
        if (this.props.authState !== "signedIn")
            return null;

        return (
            <div className="App">
                <AppHeader/>

                <Container style={{paddingTop: '6em'}} >
                    <Grid>
                        <Grid.Column mobile={16} computer={8}>
                            <SerialNumberSearch />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>
                            <RecentCertificates />
                            <CcwDeviceCerts />
                        </Grid.Column>
                    </Grid>
                </Container>

                <MessageFeed/>
            </div>
        )
    }
}
