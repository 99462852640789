import React, { Component } from 'react';
import {Segment, Header} from 'semantic-ui-react';
import certificatesStore from "../stores/CertificatesStore";
import CertificateResult from "../search/CertificateResult";

export default class RecentCertificates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            certs : certificatesStore.recentCerts,
        }

        certificatesStore.on('recentUpdated', (certs) => this.setState({certs}))
    }

    render() {
        if (!this.state.certs || this.state.certs.length === 0)
            return null;

        return (
            <Segment color='blue'>
                <Header size='large' content='Recent Certificates' dividing textAlign='center' />

                {this.state.certs.map(cert => (
                    <CertificateResult key={cert.certNumber} cert={cert} />
                ))}
            </Segment>
        )
    }
}
