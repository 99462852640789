import rg4js from 'raygun4js';

const _envSettings = {
    'local' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_bHCVEyuDl',
            userPoolWebClientId: '6bv1o74it16u6idv98l14phnvm'
        },
        'managementConsoleApi' : 'http://localhost:5001',
        'managementConsoleUrl' : 'http://localhost:3001',
        'rayGunApiKey' : '',
    },
    'dev' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_bHCVEyuDl',
            userPoolWebClientId: '6bv1o74it16u6idv98l14phnvm'
        },
        'managementConsoleApi' : 'https://dev-api.stcapplications.com/',
        'managementConsoleUrl' : 'https://dev.stcapplications.com',
        'rayGunApiKey' : '',
    },
    'staging' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_Sof0NHnPc',
            userPoolWebClientId: '4ppod1dkommofkqjsd91cca2ej',
        },
        'managementConsoleApi' : 'https://staging-api.stcapplications.com/',
        'managementConsoleUrl' : 'https://staging.stcapplications.com/',
        'rayGunApiKey' : '',

    },
    'production' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_Sof0NHnPc',
            userPoolWebClientId: '4ppod1dkommofkqjsd91cca2ej',
        },
        'managementConsoleApi' : 'https://api.stcapplications.com/',
        'managementConsoleUrl' : 'https://stcapplications.com/',
        'rayGunApiKey' : '',
    }
};

export const GetEnvironmentSetting = function(key) {
    // determine the env
    let env = GetEnvironment();
    // get the appropriate value for the env
    let value = _envSettings[env][key];
    // if we couldn't find it, alert ray gun, something is missing
    if ( value === undefined) {
        console.error('unknown environment variable', key);
        rg4js('send', new Error(`Unknown environment variable for env ${env}. KEY = ${key}. (running at location : ${window.location.href})`))
    }

    return value;
};

/**
 * @return {string}
 */
export const GetEnvironment = function() {
    return 'production';
    if (window.location.hostname.includes('localhost'))
        return 'local';
    else if (window.location.hostname.includes('dev'))
        return 'dev';
    else if (window.location.hostname.includes('staging'))
        return 'staging';

    return 'production';
};

export const ExportEnvironmentSettings = function() {
    let env = GetEnvironment();
    return _envSettings[env];
};
