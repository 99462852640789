import React, { Component } from 'react';
import {Image, Menu, Container, Dropdown, Icon} from 'semantic-ui-react';
import { AppVersion } from "../utils/AppVersion";
import {authenticationStore, Images, managementConsoleStore} from '@ametektci/ametek.stcappscommon';
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";

export default class AppHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user : null,
            organizationName : null
        }

        managementConsoleStore.on('userDataLoadedEvent', (data) => this.setState({user : data.user, organizationName : data.organizationName}))
        managementConsoleStore.loadUserData()
    }


    render() {
        return (
            <Menu fixed='top' inverted size='large'>
                <Container>
                    <Menu.Item href='/'>
                        <Image src={Images.stcLogo} size='small' className='headerImage'/>
                        Calibration Certificates
                    </Menu.Item>

                    <Menu.Menu position='right'>

                        <Dropdown item text={this.state.user ? this.state.user.name : null}>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled>
                                    v. {AppVersion}
                                </Dropdown.Item>
                                <Dropdown.Item href={GetEnvironmentSetting('managementConsoleUrl')} target='_blank'>
                                    <Icon name='user' color='red'/>
                                    {this.state.organizationName}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={authenticationStore.signOut}>
                                    <Icon name='sign-out' color='red'/>
                                    Sign Out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/*<LanguageSelection />*/}

                        {/*<Menu.Item href={GetEnvironmentSetting('documentationUrl')} target='_blank'>*/}
                        {/*    <Icon name='help circle' size='small'/>*/}
                        {/*    {textResources.Strings.help}*/}
                        {/*</Menu.Item>*/}

                    </Menu.Menu>
                </Container>
            </Menu>
        )
    }
}
