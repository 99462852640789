import React, { Component } from 'react';
import {Segment, Header, Divider} from 'semantic-ui-react';
import CertificateResult from "../search/CertificateResult";

export default class CcwDeviceResult extends Component {
    render() {
        return (
            <Segment color='blue'>

                <Header content={'SN : ' + this.props.device.serialNumber} subheader={this.props.device.model} color='blue' />

                <Divider color='grey' />

                {this.props.device.certificates.map(cert => (
                    <CertificateResult key={cert.certNumber} cert={cert} />
                ))}

            </Segment>
        )
    }
}
